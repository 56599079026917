<template>
  <div class="article">
    <van-tabs class="vantabs" v-model="active" swipeable>
      <van-tab
        v-for="item in titleArr"
        :name="item.id"
        :key="item.id"
        :title="item.name"
      >
        <div v-show="item.id === 1">
          <p><strong>1.什么是教师招聘考试?</strong></p>
          <p>
            指：具有从事教育行业的能力后(既获得教师资格证)，进行的竞争上岗考试。
          </p>
          <p><strong>2.教师招聘考试4种常见形式：</strong></p>
          <p>①大型教师招聘考试，常见叫法：教师入编考试/编制考试/国编考试</p>
          <p>
            省/市/县(区)教育局统一选拔考试，入编后属于教育局编制。注：原则上需要有资格证才能参加教师招聘考试、一般都有编制
          </p>
          <p>②事业单位(含事业单位D类教师岗)类考试</p>
          <p>
            省/市/县(区)人力资源与社会保障局统一选拔考试，入编后属于事业单位编制，待遇跟教育局编制一样，只是归属部门不同。
          </p>
          <p>③农村义务教师招聘考试(特岗教师考试)</p>
          <p>
            考试相对简单聘任制签合同三年。注：招聘要求相对较低，如果是去特别偏远的山区，可能会发资格证。
          </p>
          <p>
            ④学校单独组织的教师招聘或校园招聘，常见叫法：教师招聘考试/招教考试
          </p>
          <p>教育局或学校会单独组织考试，可能会是编制也可能是合同制。</p>
          <p>
            注，事业单位D类(教师岗)考试和特岗教师考试并不是每个省份都有，例如特岗教师招聘是国家针对西部和贫困地区省市的支援政策，引导和鼓励高校毕业生从事农村义务教育工作。
          </p>
          <p><strong>3.教师招聘考试是全国统一的吗?</strong></p>
          <p>国家整体的教师政策是：国标、省考、县聘、校用。</p>
          <p>国标：教师资格证逐渐统一标准，全国统考。</p>
          <p>省考：教师招聘考试逐渐会以省为主来统考。</p>
          <p>县聘：和县教育局签订合同。</p>
          <p>校用：学校使用老师。</p>
          <p>所以，考证是全国统考，考编就是各省自行组织了。</p>
          <p>目前进行教师招聘入编全省统考的省份有：福建、江西、安徽、广西;</p>
          <p>省或各市联考的省份有：四川、天津、山东;</p>
          <p>进行市统考的地区有：深圳。</p>
          <p>
            注：其他省份或地区，无统一考试，全年散考，需要根据具体省份地区的公告;事业单位D类及特岗考试省/市单独组织，不做说明。
          </p>
          <p><strong>4.什么时候参加考试?</strong></p>
          <p>
            如上所述，各地有自己的时间安排。全国教师招聘考试集中在3-8月，其他月份也会有不同的地方进行招考。
          </p>
          <p><strong>5.教师招聘考试考什么?</strong></p>
          <p>一般来说，考试都分两个阶段：笔试和面试。</p>
          <p>
            笔试：有些地方只考一门：教育综合知识(又叫：教育基础知识、教育理论知识、教育教学知识、教育知识……)。有些地方考两门：教育综合知识+X。这个随着各地考情不同而不同：有的考学科专业知识，有的地方考公共基础知识，有的地方考行测。
          </p>
          <p>
            面试一般在笔试出成绩后2~4周内进行，各区县单独组织。考试形式有3种：试讲、说课、结构化。
          </p>
          <p><strong>6.教育综合知识包含什么?</strong></p>
          <p>
            教育综合知识的主体是这4大模块：教育学、心理学、教育心理学、教育法规与师德。各地大纲不同，但知识体系主体是这四部分内容。
          </p>
          <p><strong>7.报考时考试信息显示不完全?</strong></p>
          <p>
            答：建议大家按照报名网站上的提示，使用高版本的Internet
            Explorer(IE)浏览器浏览教招网络报考平台。
          </p>
          <p>
            <strong>8.考生注册后，发现证件号或者姓名填写错误，如何修改?</strong>
          </p>
          <p>
            答：注册信息必须以真实姓名注册,一经注册，不允许修改姓名、身份证号。如考生报名后未支付考试费用的，可使用正确的证件号和姓名重新注册报名;已支付费用的，请联系当地人事考试机构。
          </p>
          <p>
            <strong>9.如果非证件号和姓名的个人信息错误，该如何修改?</strong>
          </p>
          <p>
            答：考生在未被招聘单位进行“资格审核”或“放弃岗位改报其他岗位”等状态下，均可进行修改个人基本信息。但个人平台岗位审核结果查询显示“已受理”、“审核通过”、“申诉”等状态下不允许修改基本信息。
          </p>
          <p><strong>10.考生注册时如何输入姓名中的生僻字?</strong></p>
          <p>
            答：目前Windows操作系统支持GBK(扩展字库)，内含大部分生僻字，可以使用搜狗拼音输入法输入，如果找不到所需要的汉字，请下载并安装华宇拼音输入法和汉字大字库(内含75000个汉字)，用华宇拼音输入法的拼音或部首法输入，如果仍找不到所需汉字请用同音字加中括号代替。
          </p>
          <p>
            示例：如姓名为王晓[沛]，代表沛为生僻字，其正确写法是草字头下面一个北京的北字。
          </p>
          <p><strong>11.已注册考生忘记密码，应如何找回?</strong></p>
          <p>
            答：若考生忘记密码，可以输入手机号等有效信息进行验证，通过验证后即可输入新的密码。
          </p>
          <p><strong>12.身份证号含“X”，如何正确输入?</strong></p>
          <p>
            答：身份证最后一位是“X”的输入大小写皆可。系统会自动将其转换为大写。
          </p>
          <p><strong>13.多次提交的报考照片初审未通过该怎么办?</strong></p>
          <p>
            答：教师招聘报考网上报名，要求提交的照片五官端正，着装整齐，清晰。很多考生应聘报考不够重视，提交的照片不符原因很多。主要有以下几种情形：
          </p>
          <p>
            (1)未按要求使用白底的标准数码身份证件照;如传统的蓝底、红底、灰暗自拍照都会被拒绝审核通过;
          </p>
          <p>(2)提交相片模糊不清，扭曲变形等;</p>
          <p>(3)有的提交了生活照(选美照);</p>
          <p>
            解决办法：请务必按照报考公告或是报名网站上的报名提示，上传符合要求的照片。
          </p>
          <p>
            <strong
              >14.如果教师报考岗位若未被初审通过，考试报名费可以退还吗?</strong
            >
          </p>
          <p>
            答：可以。若考生应聘报考岗位提交给有关招聘单位“初审不通过”，可以提出申诉或另选择别的招聘单位应聘报考。若申诉不成功或放弃这个报考岗位志愿资格，招聘系统将在报考期结束一个月内，将网上报考报名费原路退回考生的银行卡帐户。
          </p>
          <p>
            <strong
              >15.应聘岗位数限制的要求及在初审不通过的应聘者，可再补报别的县(区)、市教师岗位可以吗?</strong
            >
          </p>
          <p>
            答:
            全省统一笔试报名以县(市、区)为单位进行，每位应聘者报考1个岗位(即1个市、县&lt;
            区
            &gt;)。应聘考生在个人平台中可对“审核不通过”的岗位选择“放弃该岗位改报其它岗位”，并可使用已支付的报名费用进行重补报其他招聘岗位。
          </p>
          <p>
            <strong
              >16.如果对招聘单位发布的教师岗位要求与资格条件有疑问的，如何咨询?</strong
            >
          </p>
          <p>
            答：报考公告中一般会公布招聘单位或招聘负责人的电话号码，考生可以直接与其联系询问教师岗位要求和资格条件限制。注意：虽然有的招聘单位周末或晚间也有安排值班，但还是建议考生最好按正常上班时间向当地招聘单位或负责人联系，以免发生不必要的矛盾等。若有政策性问题、效能问题请向招聘单位所在地的业务主管部门投诉。
          </p>
          <p><strong>17.有了教师资格证就可以应聘教师吗？</strong></p>
          <p>
            教师资格证是从事教师职业的“入门证”，有了它，既可以报考由人力资源和社会保障部门会同教育部门组织的公办教师入编招聘考试，也可以报考在部分省市自治区举行的特岗教师考试，当然也可以应聘一些教育培训机构的教师岗位。
          </p>
          <p>
            <strong
              >18.如何才能顺利通过教师招聘考试？教师招聘考试应该注意哪些步骤？</strong
            >
          </p>
          <p>第一步：考取教师资格证</p>
          <p>①参加教师资格证国考考试考生，需通过笔试、面试拿到成绩合格证明；</p>
          <p>
            ②在教师资格证认定前考取普通话水平等级证书(教师普通话水平要求二乙以上，如果想当语文老师，普通话水平得达到二甲水平，幼教部分地区也要求普通话水平达到二甲水平，具体要参看当地的教师资格证认定公告)；
          </p>
          <p>
            ③根据当地的教师资格证认定公告要求，申报，并带上身份证、学历证明、体检表等材料去认定，审核通过后即可取得教师资格证!
          </p>
          <p>第二步：参加教师招聘考试</p>
          <p>
            查看省/市/县(区)教育局或人力资源与社会保障局或学校发布的教师招聘公告，符合报名要求及时报名，一般会经过笔试-面试-考核-录用-签约等层层筛选，一切顺利就算成功上岸，成为一名正式老师啦!
          </p>
          <p>
            注：教师招聘考试形式不同，可能有编制也可能是合同制，具体需要查看考试的公告了解具体情况。
          </p>
          <p>
            <strong
              >19.我已经拿到了教师资格证，如果要应聘老师是否还要考编制?</strong
            >
          </p>
          <p>
            答：拿到教师资格证，只不过是具备了从业资格，可以聘用为教师。按照机关事业单位“逢进必考”原则，还要经过考试录用。考试录用必须经过相关部门的组织考试，录用后即成为编制内的教师，即“在编人员”。录用后如果学校因超编而成为”编外人员“，实际上仍是我们俗称的”在编“人员，对其无影响，坐等空编再入编即可。
          </p>
          <p>
            <strong
              >20.考的编制之后是不是只能在所考地有用?具体是以什么为单位?县还是地级市?</strong
            >
          </p>
          <p>
            答：考的编制只能在当地有用，一般以市为单位。因各地政策落实不一样，所以存在差别。以江西省为例，近几年县一级单位一般不组织考试，凡事业单位考试都由市以上统一组织考试，教师考试由省统一考试。所以，理论上一般在市一级之间可以调动，即可以由A市调动至B市。但是，一般跨县调动都很难，所以实际操作中，跨市调动非常难。
          </p>
          <p>
            <strong
              >21.如果考的编制只能在当地使用，那这岂不是相当的不合理，好不容易考了个编制到了外地却不能用也太郁闷了!</strong
            >
          </p>
          <p>
            答：原则是可以调动。前几年，有文件出台，原则上不允许从教育系统调出至其他单位。
          </p>
          <p>
            <strong
              >22.应聘老师的流程是什么?a.取得教师资格证b.考编制c.到学校应聘吗?</strong
            >
          </p>
          <p>
            答：a、b流程对。应聘手续一般是按照当地的招考公考报名要求，参加笔试和面试后，体检并公示，然后到当地的人事部门报到，听从岗位分配，到学校报到。
          </p>
          <p>
            <strong
              >23.考编制的大约时间是什么?每年可以考几次?大体都考些什么内容?</strong
            >
          </p>
          <p>
            答：教师资格证和教师招聘考试属于不同的考试，一个是资格考试，即从业资格，一个是事业单位工作人员招聘考试。教师资格证考试从2015年起施行全国统考，考试时间按照要看国家出台的相关考试通告;教师招聘考试要看当地的具体情况。有些由省一级统一组织考试，有些由市一级组织考试，具体考试时间请留意当地人事考试网。资格考试和考试内容你请留意当地的招考公告里的考试大纲。教师尽管是事业编制，和公务员编制是不一样的，调出教育系统，一样可以提拔为副科、正科等!
          </p>
          <p><strong>24.教师招聘和特岗教师招聘考试有哪些不同点？</strong></p>
          <p>
            教师招聘和特岗教师招聘有哪些不同点？现在为山香网校大家解惑下关于教师招聘和特岗教师招聘四大不同点分析解惑：
          </p>
          <p>一、考试形式上的不同</p>
          <p>
            1.教师招聘考试是以县或地级市、区进行考试的，但特岗教师招聘考试是由各省教育厅来执行教育部的有关政策进行招聘考试。
          </p>
          <p>
            教师招聘考试县或地级市、区的时间不是很固定，因为它是由当地教师缺编情况而定的，而特岗教师招聘是以省为单位统一进行考试的，每年仅有一次。
          </p>
          <p>
            2.特岗教师招聘考试是采用网上进行报名，而教师招聘考试的报名形式并不是很统一，有一些是采用网上报名，大部分是采用现场报名的方式。
          </p>
          <p>二、招聘岗位学段上的不同</p>
          <p>
            教师招聘具体指的是事业单位编制的普通岗位教师，并且普岗教师不限于农村义务教育阶段学校，同时在幼儿园到大学都会有。但特岗教师他只会招聘义务教育学段岗位。
          </p>
          <p>1.从考试题型来看</p>
          <p>
            教师招聘考试题型并不是固定，根据以往来说涉及题型有：单选，多选，不定项，判断，名词解释，案例分析，简答，论述，写作等。特岗教师招聘考试大部分地区题型还是比较固定的，如河南特岗近几年的题型均为：选择题，判断，材料分析，教案设计，论述，作文，没有存在什么太大的变化。
          </p>
          <p>2.从考试内容来看</p>
          <p>
            教师招聘考试笔试具体内容是由各地或所招聘单位来进行确定的，对于不同地区教师招聘考试具体内容会有所不同，具体以当地公告为准。全国大部分地区的特岗教师招聘考试的笔试内容是由全省统一命题的。
          </p>
          <p>三、报名条件上的不同</p>
          <p>
            不同教师招聘考试报名条件也不同，具体情况需要根据教师招聘考试的具体公告来进行确定。而特岗教师招聘考试的报名条件是全省统一规定的。
          </p>
          <p>1.从专业方面来说</p>
          <p>
            一般情况，河南教师招聘考试基本上关注考生的专业和教师资格证的学科是否相匹配，而河南特岗教师招聘考试在专业方面的限制比教师招聘考试相对较少。
          </p>
          <p>2.从学历方面来说</p>
          <p>
            对于教师招聘而言，学历条件要求是由当地有关部门决定的，所在地区不同，学历条件要求也会有所不同。特岗教师的招聘学历条件一般都是一样的，大部分地区学历要求本科，不过有部分地区专科也可以参加，学历条件具体要以当地公告为主。
          </p>
          <p>四、在编制待遇的不同</p>
          <p>
            教师招聘考试在笔试和面试、体检通过的情况下，一般可以说编制就拿到手了，而特岗教师3年服务期内是没有相关的编制，并且工资的发放分为国家拨款一部分和县财政补助一部分，保险公积金也是由当地县财政负责解决。当3年服务期满时，特岗教师才能够转为正式编制的教师，在这3年服务期当中也会算教龄和评相关的职称的凭证。
          </p>
          <p><strong>25.教师招聘考试需要哪些步骤才能参加考试</strong></p>
          <p>
            那么如何才能顺利通过教师招聘考试？看看下面这几个步骤你走到哪里了？
          </p>
          <p>第一步：考取教师资格证</p>
          <p>①参加教师资格证国考考试考生，需通过笔试、面试拿到成绩合格证明;</p>
          <p>
            ②在教师资格证认定前考取普通话水平等级证书(教师普通话水平要求二乙以上，如果想当语文老师，普通话水平得达到二甲水平，幼教部分地区也要求普通话水平达到二甲水平，具体要参看当地的教师资格证认定公告);
          </p>
          <p>
            ③根据当地的教师资格证认定公告要求，申报，并带上身份证、学历证明、体检表等材料去认定，审核通过后即可取得教师资格证!
          </p>
          <p>第二步：参加教师招聘考试</p>
          <p>
            查看省/市/县(区)教育局或人力资源与社会保障局或学校发布的教师招聘公告，符合报名要求及时报名，一般会经过笔试-面试-考核-录用-签约等层层筛选，一切顺利就算成功上岸，成为一名正式老师啦!
          </p>
          <p>
            注：教师招聘考试形式不同，可能有编制也可能是合同制，具体需要查看考试的公告了解具体情况。
          </p>
        </div>
        <div v-show="item.id === 2">
          <p><strong>1.中小学教师资格考试的依据是什么？</strong></p>
          <p>
            中小学教师资格考试是贯彻落实《国家中长期教育改革和发展规划纲要（2010—2020年）》的重要举措，是依据《教育部关于开展中小学和幼儿园教师资格考试改革试点的指导意见》（教师函〔2011〕6号）和《教育部办公厅关于2012年扩大中小学教师资格考试改革和定期注册制度试点工作的通知》（教师厅〔2012〕1号）文件开展实施的考试项目。
          </p>
          <p><strong>2.中小学教师资格考试的意义是什么？</strong></p>
          <p>
            教师是实施素质教育，提高教育质量的关键。开展中小学教师资格考试改革试点，完善并严格实施教师职业准入制度，是建设高素质、专业化、创新型教师队伍的重要任务，是建立健全中国特色教师管理制度的重要内容，对于提升教师队伍整体素质，提高教师社会地位，吸引优秀人才从教，推动教育改革发展，具有重要意义。
          </p>
          <p><strong>3.中小学教师资格考试试点的目标是什么？</strong></p>
          <p>
            通过中小学教师资格考试改革试点，建立国家教师资格考试标准，改进考试内容，强化职业道德、心理素养、教育教学能力和教师专业发展潜质，改革考试形式，加强考试管理，完善考试评价，引导教师教育改革，严把教师职业入口关，结合新任教师公开招聘制度改革，逐步形成“国标、省考、县聘、校用”教师准入和管理制度
          </p>
          <p><strong>4.中小学教师资格考试改革试点的主要原则是什么？</strong></p>
          <p>
            总体设计，分步实施。统筹设计教师职业准入制度和国家教师资格考试制度，在部分地区先行试点，总结经验，逐步推开。
          </p>
          <p>
            因地制宜，分区指导。依据教育部制定的中小学教师资格考试标准和考试大纲，试点省份结合实际组织实施，发挥地方的主动性、积极性和创造性。
          </p>
          <p>
            以人为本，公平择优。以鼓励和吸引优秀人才从事教育工作为宗旨，为考生提供专业化的考试服务，提供多次机会和便利条件。坚持考试的科学性、规范性和公正性，择优选拔乐教、适教的人员取得教师资格。
          </p>
          <p><strong>5.中小学教师资格考试的对象是哪些人？</strong></p>
          <p>
            试点省份内所有申请幼儿园、小学、初级中学、普通高级中学、中等职业学校教师资格和中等职业学校实习指导教师资格的人员须参加中小学教师资格考试。
          </p>
          <p>
            试点工作启动前已入学的全日制普通院校师范类专业学生，可以持毕业证书申请直接认定相应的教师资格。试点工作启动后入学的师范类专业学生，申请教师资格应参加中小学教师资格考试。
          </p>
          <p><strong>6.中小学教师资格考试分几种类型？</strong></p>
          <p>
            中小学教师资格考试包括：幼儿园教师资格考试、小学教师资格考试、初级中学教师资格考试、普通高级中学教师资格考试、中等职业学校文化课教师资格考试、中等职业学校专业课教师资格考试、中等职业学校实习指导教师资格考试。
          </p>
          <p><strong>7.中小学教师资格考试采用什么考试方法？</strong></p>
          <p>
            中小学教师资格考试分为笔试和面试两部分。笔试所有科目均采用纸笔考试。笔试各科考试成绩合格，才能报名参加面试。
          </p>
          <p>
            面试采取结构化面试、情境模拟等方式，通过抽题、备课（活动设计）、回答规定问题、试讲（演示）、答辩（陈述）、评分等环节进行。
          </p>
          <p><strong>8.符合什么条件才能报考中小学教师资格考试？</strong></p>
          <p>符合以下基本条件的人员，可以报名参加中小学教师资格考试：</p>
          <p>
            （一）具有中华人民共和国国籍，或在内地（大陆）学习工作生活的港澳台居民；
          </p>
          <p>
            （二）遵守《中华人民共和国宪法》和法律，拥护中国共产党领导，坚持社会主义办学方向，贯彻党的教育方针，热爱教育事业，具有良好的思想品德；
          </p>
          <p>（三）符合申请认定教师资格的体检标准；</p>
          <p>
            （四）原则上应具备《教师法》规定的相应学历条件，并应符合本省确定并公布的学历要求。
          </p>
          <p>
            普通高等学校在校三年级以上学生，可凭学校出具的在籍学习证明报考。
          </p>
          <p><strong>9.中小学教师资格考试报考地点有什么要求？</strong></p>
          <p>
            考生应在户籍或居住证（有效期内）申领所在地报名参加教师资格考试。普通高等学校在校生可在就读学校所在地报名参加教师资格考试。
          </p>
          <p>
            <strong>10.中小学教师资格考试报名使用的有效证件有什么要求？</strong>
          </p>
          <p>
            大陆居民报考中小学教师资格考试有效证件为：有效期内的居民身份证。
          </p>
          <p>
            在内地（大陆）学习、工作和生活的港澳台居民报考中小学教师资格考试的有效证件为：港澳台居民居住证、港澳居民来往内地通行证、五年有效期台湾居民来往大陆通行证。
          </p>
          <p><strong>11.哪些人不能参加中小学教师资格考试？</strong></p>
          <p>
            根据有关规定，被撤销教师资格的，5年内不得报名参加考试；受到剥夺政治权利，或故意犯罪受到有期徒刑以上刑事处罚的，不得报名参加考试。参加教师资格考试有作弊行为，按照《国家教育考试违规处理办法》受到禁考处罚的，在禁止期内不得报名参加考试。
          </p>
          <p><strong>12.中小学教师资格考试的命题依据是什么？</strong></p>
          <p>
            命题依据是中小学教师资格考试标准和考试大纲。考试标准包括幼儿园教师资格考试标准、小学教师资格考试标准、初级中学教师资格考试标准、普通高级中学教师资格考试标准。考试大纲包括笔试和面试两部分。
          </p>
          <p><strong>13.中小学教师资格考试笔试主要考核什么内容？</strong></p>
          <p>
            笔试主要考查申请人从事教师职业所应具备的思想政治素质、教育理念、职业道德、法律法规知识、科学文化素养、阅读理解、语言表达、逻辑推理和信息处理等基本能力；教育教学、学生指导和班级管理的基本知识；拟任教学科领域的基本知识，教学设计实施评价的知识和方法，运用所学知识分析和解决教育教学实际问题的能力。
          </p>
          <p>
            申请幼儿园教师资格的，笔试还应考查幼儿教育基本理论、原理，保教知识与能力。
          </p>
          <p><strong>14.中小学教师资格考试面试主要考核内容是什么？</strong></p>
          <p>
            申请幼儿园教师资格的，面试主要考查职业认知、心理素质、仪表仪态、交流沟通、思维品质、了解幼儿、技能技巧、评价反思等教育教学基本素养和技能。
          </p>
          <p>
            申请小学、中学教师资格的，面试主要考查申请人的职业认知、心理素质、仪表仪态、言语表达、思维品质等教师基本素养和教学设计、教学实施、教学评价等教学基本技能。
          </p>
          <p><strong>15.幼儿园教师资格考试的笔试考试包括哪些科目？</strong></p>
          <p>
            幼儿园教师资格考试笔试为2个科目，科目一为《综合素质》（幼儿园），科目二为《保教知识与能力》。
          </p>
          <p><strong>16.小学教师资格考试的笔试考试包括哪些科目？</strong></p>
          <p>
            小学教师资格考试笔试为2个科目，科目一为《综合素质》（小学），科目二为《教育教学知识与能力》。
          </p>
          <p>
            <strong
              >17.初级中学和高级中学教师资格考试的笔试包括哪些科目？</strong
            >
          </p>
          <p>初级中学和普通高级中学教师资格考试笔试为3个科目：</p>
          <p>
            科目一均为《综合素质》（中学）；科目二均为《教育知识与能力》；科目三为《学科知识与教学能力》。
          </p>
          <p>
            初级中学的《学科知识与教学能力》科目分为：语文、数学、英语、物理、化学、生物、思想品德、历史、地理、音乐、体育与健康、美术、信息技术、历史与社会、科学等15个学科。
          </p>
          <p>
            普通高级中学的《学科知识与教学能力》科目分为：语文、数学、英语、物理、化学、生物、思想政治、历史、地理、音乐、体育与健康、美术、信息技术、通用技术等14个学科。
          </p>
          <p><strong>18.申请认定中等职业学校教师资格人员如何报考？</strong></p>
          <p>
            申请认定中等职业学校文化课教师资格人员考试科目与普通高级中学教师资格考试类别相同，需报考科目一《综合素质》（中学）、科目二《教育知识与能力》、科目三《学科知识与教学能力》。
          </p>
          <p>
            申请认定中等职业学校专业课和实习指导教师资格的人员考试科目为普通高级中学教师资格考试类别的科目一《综合素质》（中学）和科目二《教育知识与能力》两科考试。
          </p>
          <p>
            <strong
              >19.中小学教师资格考试对音、体、美专业考生报考公共科目有何规定？</strong
            >
          </p>
          <p>
            根据教育部文件精神，报考小学、初级中学、普通高级中学、中职文化课类别音、体、美专业的考生,笔试科目一和科目二的代码为201A、202A、301A、302A，考试内容暂与原科目相同。上述考生在笔试报名时应选报科目代码含“A”的科目。
          </p>
          <p>
            取得201A、202A科目合格的考生，面试仅限于参加小学类别音、体、美专业科目；取得301A、302A科目合格的考生，面试仅限于参加初中、高中、中职文化课类别音、体、美专业科目。
          </p>
          <p>
            考生获得201、202、301、302科目合格成绩，可相应替代201A、202A、301A、302A科目合格成绩；考生获得201A、202A、301A、302A科目合格成绩不可替代201、202、301、302科目合格成绩。
          </p>
          <p><strong>20.综合素质主要考察哪些知识与能力？</strong></p>
          <p>
            《综合素质》主要考查考生职业理念、教育法律法规、教师职业道德规范、文化素养和基本能力。
          </p>
          <p><strong>21.保教知识与能力主要考察哪些知识与能力？</strong></p>
          <p>
            《保教知识与能力》主要考查考生学前儿童发展、学前教育原理、生活指导、环境创设、游戏活动的指导、教育活动的组织与实施、教育评价。
          </p>
          <p><strong>22.教育教学知识与能力主要考察哪些知识和能力？</strong></p>
          <p>
            《教育教学知识与能力》主要考查考生教育基础、学生指导、班级管理、学科知识、教学设计、教学实施、教学评价。
          </p>
          <p><strong>23.教育知识与能力主要考察哪些知识和能力？</strong></p>
          <p>
            《教育知识与能力》主要考查考生教育基础知识和基本原理、中学教学、中学生学习心理、中学德育、中学课程、中学生发展心理、中学生心理辅导、中学班级管理与教师心理。
          </p>
          <p><strong>24.学科知识和教学能力主要考察哪些知识和能力？</strong></p>
          <p>
            《学科知识与教学能力》主要考查考生相应学科知识、教学设计、教学实施和教学评价的内容。
          </p>
          <p><strong>25.中小学教师资格考试笔试主要题型有哪些？</strong></p>
          <p>
            笔试的题型分客观题和主观题。客观题为四选一的选择题，主观题题型包括：简答、论述、解答、材料（案例）分析、课例点评、诊断、辨析、教学设计、活动设计、写作等。
          </p>
          <p><strong>26.教学设计题的主要考核功能是什么？</strong></p>
          <p>
            主要考查考生课堂教学方案设计能力和水平。试题提供教学设计需要的相关信息，如背景材料、课标要求、教学内容、学生概况、教材内容节录等，要求考生写出教学方案片段，或者教学活动基本框架、主要步骤等，是学科知识与教学能力科目普遍使用的题型。
          </p>
          <p><strong>27.材料（案例）分析题的主要考核功能是什么？</strong></p>
          <p>
            主要考查考生运用相关知识评析、解释、说明所给材料的综合性能力。要求考生能够准确处理和解决材料（案例）与问题间的关系。材料（案例）包括教学实录、教学方案、学生作业、教学反思记录、教研论文、教材内容节录、教育教学热议现象等，材料（案例）以文字、图表等多种形式呈现，试题答案比较开放，是各科目普遍使用的题型。
          </p>
          <p><strong>28.活动设计题的主要考核功能是什么？</strong></p>
          <p>
            活动设计题是保教知识与能力科目使用的题型。试题提供幼儿园活动设计需要的相关信息，如背景材料、幼儿班级、主题、时间、活动类型等，要求考生根据提供的信息设计活动方案。
          </p>
          <p><strong>29.课例点评题的主要考核功能是什么？</strong></p>
          <p>
            课例点评题是中学音乐学科知识与教学能力科目使用的题型。通过呈现有代表性、典型性的中学音乐课堂教学案例，要求考生运用相关知识进行分析、评论，回答相关问题。
          </p>
          <p><strong>30.中小学教师资格考试如何报名？</strong></p>
          <p>
            1、登录。考生登录“中小学教师资格考试网”（http://ntce.neea.edu.cn），查看所在省报名公告，按照导航页面指引进入所在省报名入口。
          </p>
          <p>
            2、注册。考生须认真阅读、充分了解本省报考要求后方可进行。注册时要求考生填写报名公告规定的证件号码、姓名、手机号码、登录密码等信息，并在网站认真阅读《诚信考试承诺书》（电子版），确认承诺后方可进行后续操作。
          </p>
          <p>
            3、填报个人信息。考生本人须如实填写户籍所在地、居住证申领所在地、是否在校生、是否师范专业、毕业学校、在校生学籍学号、最高学历及证书编号等信息，并按照格式要求上传证件照（免冠、正面、彩色、白底，JPG/JPEG格式，不大于200K）。
          </p>
          <p>
            4、填报报考信息。考生按照本省公告要求选择考试类别、考区、科目（所报科目须一次性勾选，一经审核通过，不得增加或更改）。提交信息后，考生报考状态变更为“待审核”。考生报考有关信息在审核前均可以自行修改。
          </p>
          <p>
            5、接受审核。各省考务工作人员将在规定期限内对考生报考信息进行审核，是否需要考生到达现场详见各省报名公告要求。审核通过的考生状态变更为“待支付”。未通过审核的考生，可在报名截止日期前修改、完善报名有关信息，重新提交报考信息，重新接受审核。
          </p>
          <p>
            6、网上缴费。各省考生均可通过网上缴纳考试费。完成缴费考生的状态变更为“报名成功（已缴费）”，完成报名。通过审核，但逾期未缴费考生，报名无效。一旦报名成功并完成缴费，概不退费。
          </p>
          <p>7、打印准考证。考生考前一周可通过报名网站自行下载、打印准考证。</p>
          <p>
            8、考试资料下载。各类别、科目考试要求在《考试大纲》中均有详细说明，考生可通过报名网站“资料下载”栏目免费下载。
          </p>
          <p><strong>31.考生登录报名系统如密码遗忘怎么办？</strong></p>
          <p>登录报名系统如密码遗忘可以通过以下3种方式重置密码:</p>
          <p>
            1、自助重置密码。考生可通过回答注册时的“密码保护问题”自行重置密码。
          </p>
          <p>2、短信获取密码。考生可通过注册时的手机号码，通过短信获取密码。</p>
          <p>
            3、拔打考试中心客服电话。考生可拨打教育部考试中心客服电话通过客服（010-82345677）人工协助重置密码。
          </p>
          <p>
            <strong
              >32.已参加过之前批次的教师资格考试，再次报名还需要重新注册吗？</strong
            >
          </p>
          <p>
            所有考生（含以前参加过国家统考笔试的考生）笔试报名前均需要重新注册、填报个人信息和上传个人照片。重新注册不影响考生已获得的笔试和面试成绩。
          </p>
          <p>
            参加当次笔试的考生，面试报名时不用再次注册。如没有参加当次笔试，面试报名需要重新注册。
          </p>
          <p><strong>33.如何进行报名资格审查？</strong></p>
          <p>报名资格审核要求参见所在省报名公告。</p>
          <p><strong>34.准考证如何获取？</strong></p>
          <p>
            考生在考试前一周登录中小学教师资格考试网站（http://ntce.neea.edu.cn），自行下载、打印《准考证》，并按照准考证规定的时间、地点参加考试。
          </p>
          <p><strong>35.考生参加考试需要带哪些证件？</strong></p>
          <p>
            考生参加考试时，须持《准考证》、有效期内居民身份证/港澳台居民居住证/港澳居民来往内地通行证/台湾居民来往大陆通行证（五年有效期）进入考场，“两证”缺一不可。
          </p>
          <p>
            考生《准考证》上的“姓名”和“证件号码”信息与所持上述身份证件上信息不一致者，不得参加考试。
          </p>
          <p><strong>36.考生参加考试在时间方面有哪些规定？</strong></p>
          <p>
            考生笔试应在开考前30分钟到达考场，正式开考后，迟到15分钟后，不准进入考场参加当次科目考试；考生考试结束前30分钟，方可交卷离开考场；考生交卷出场后不得再进场续考。
          </p>
          <p>
            考生面试应按照《准考证》上规定的时间到达候考室，在截止进入候考室时间15分钟后迟到的考生，不得参加面试。
          </p>
          <p><strong>37.参加面试报名的基本条件是什么？</strong></p>
          <p>
            考生参加中小学教师资格考试，所报类别笔试各科成绩均合格，且成绩在有效期内者，方可参加面试报名。
          </p>
          <p><strong>38.考生参加面试的流程是怎样的？</strong></p>
          <p>
            1、候考。考生持本人《准考证》及符合规定的有效身份证件，按照规定时间到达测试地点，进入候考室，完成身份核验。
          </p>
          <p>
            2、抽题。考生进入抽题室，登录面试测评软件系统，抽取面试试题（幼儿园类别可从抽取的2道试题中任选1道，其余类别抽取1道试题），考生确认后，打印面试试题清单。
          </p>
          <p>
            3、备课。考生携带准考证、试题清单进入备课室，撰写教案(或活动演示方案)，准备时间共20分钟。
          </p>
          <p>
            4、回答规定问题。考生由工作人员引导进入指定面试室。面试考官通过面试测评系统从试题库中随机抽取2道规定问题，告知考生问题并要求考生开始回答，时间5分钟左右。
          </p>
          <p>
            5、试讲 /
            演示。考生按照准备的教案（或活动演示方案）进行试讲(演示)，时间10分钟。
          </p>
          <p>
            6、答辩。考官围绕考生试讲（或演示）等相关内容进行提问，考生答辩，时间5分钟左右。
          </p>
          <p>
            7、离场 /
            评分。考生将教案（或活动演示方案）呈交面试考官，及时离开面试室。考官依据评分标准进行综合评分。
          </p>
          <p><strong>39.面试分科目报名吗？</strong></p>
          <p>幼儿园教师资格考试面试不分科目。</p>
          <p>
            小学教师资格考试面试科目分为小学语文、小学英语、小学社会、小学数学、小学科学、小学音乐、小学体育、小学美术、*小学信息技术、*小学心理健康教育、*小学全科。
          </p>
          <p>
            初级中学、普通高级中学、中职文化课类别教师资格考试面试科目，与笔试科目三“学科知识与教学能力”相同。报考“*日语”、“*俄语”、“*心理健康教育”3科考生，该类别笔试科目一、科目二合格即可进行面试报名。
          </p>
          <p>
            注：部分省份未开考带 * 科目，有关开考情况请考生关注所在省报名公告。
          </p>
          <p>
            <strong
              >40.中小学教师资格考试成绩合格科目有效期是如何规定的？</strong
            >
          </p>
          <p>各类别中小学教师资格考试（笔试）单科成绩有效期均为2年。</p>
          <p>
            例如：参加2019年上半年笔试，合格科目有效期至2021年6月30日；参加2019年下半年笔试，合格科目有效期至2021年12月31日。
          </p>
          <p>
            各类别中小学教师资格考试（面试）科目合格成绩单次有效。面试通过即可获得《中小学教师资格考试合格证明》，该合格证明有效期为3年。
          </p>
          <p>
            例如：通过2019年上半年面试，合格证明有效期至2022年6月30日；通过2019年下半年面试，合格证明有效期至2022年12月31日。
          </p>
          <p><strong>41.如何获取《中小学教师资格考试合格证明》？</strong></p>
          <p>
            笔试和面试科目均合格的考生，可获得由教育部考试中心颁发的《中小学教师资格考试合格证明》。面试成绩公布后，成绩合格的考生，可自行登录网站“合格证查询”栏目，查询、下载、打印PDF格式“网页版”的考试合格证明。该证明是申请教师资格认定的必要条件，有效期3年。
          </p>
          <p><strong>42.考试合格和教师资格认定有什么关系？</strong></p>
          <p>
            （1）教师资格考试成绩合格是取得教师资格的必备条件之一。要取得教师资格，必须取得教师资格考试成绩合格，同时具备教师资格的其它条件。
          </p>
          <p>
            （2）教师资格证书任教学科应与申请人报考笔试科目“学科知识与教学能力”一致。
          </p>
        </div>
        <div v-show="item.id === 3">
          <p><strong>1.什么是特岗教师？</strong></p>
          <p>
            答：特岗教师政策是中央实施的一项对中西部地区农村义务教育的特殊政策，通过公开招聘高校毕业生到乡镇及以下农村学校任教，引导和鼓励高校毕业生从事农村义务教育工作，创新农村学校教师的补充机制，逐步解决农村学校师资总量不足和结构不合理等问题，提高农村教师队伍的整体素质，促进城乡教育均衡发展。
          </p>
          <p><strong>2.国家特岗教师和地方特岗教师有什么区别？</strong></p>
          <p>
            答：中央“特岗计划”实施范围为我省原国贫县、省贫县和连片特困县，其他县（市）为地方特岗计划。中央和地方“特岗计划”除财政支持渠道不同外，其他方面均一致。对特岗教师没有任何区别和影响。
          </p>
          <p><strong>3.特岗教师招聘的对象是？</strong></p>
          <p>
            答：全日制普通高校应、往届本科及以上毕业生；全日制普通高校师范类专业应、往届专科毕业生；上述招聘对象均要求年龄在30周岁以下。
          </p>
          <p><strong>4.报考特岗教师有什么的流程？</strong></p>
          <p>
            答：查阅特岗教师招聘公告→网上报名→报名资格审查→打印准考证→笔试→面试→体检→拟录用名单公示→办理聘用手续。
          </p>
          <p><strong>5.特岗教师报名的方式是？</strong></p>
          <p>
            答：特岗教师招聘采用网上报名的方式进行。特岗教师招聘不收取报名考务费。
          </p>
          <p><strong>6.特岗教师招聘对教师资格证的种类有要求吗？</strong></p>
          <p>
            答：2020年特岗教师招聘教师资格实施“先上岗、再考证”阶段性措施，教师资格不作为特岗教师招聘报考的限制性条件。但体育、音乐、美术、心理健康教育岗位对教师资格或所学专业有一定限制。2021年的特岗教师招聘是否还实施“先上岗、再考证”阶段性措施，目前还不确定，届时以公告为准。
          </p>
          <p><strong>7.哪些岗位有特殊的报考要求？</strong></p>
          <p>
            答：应聘初中教师岗位的，要求本科及以上学历。应聘体育、音乐、美术、心理健康教育岗位的，要求所持有的教师资格证书任教学科与报考岗位一致，或所学专业与报考岗位一致、相近。
          </p>
          <p><strong>8.幼儿园教师资格可以报考哪些岗位？</strong></p>
          <p>
            答：在满足学历、年龄等条件的前提下，持有幼儿园教师资格证书的毕业生、学前教育专业毕业生，可以报考音乐、美术、语文、数学等岗位，不能报考体育、心理健康教育岗位。&nbsp;
          </p>
          <p><strong>9.已经签约的特岗教师能否再次报考？</strong></p>
          <p>
            答：尚在服务期内的特岗教师，不允许再次报考特岗教师岗位。如服务期内的特岗教师已经与设岗县解除服务协议，且县教育局在特岗教师管理系统内将其状态设置为离岗，则可以再次报考特岗教师。
          </p>
          <p><strong>10.函授、自考、脱产等学历可以报考特岗教师吗？</strong></p>
          <p>
            答：特岗教师招聘均要求全日制普通高校毕业生，成人高考（函授、业余、脱产）、自学考试、网络教育、远程教育的毕业生等均不具备报名资格。
          </p>
          <p><strong>11.留学回国人员如何报考特岗教师？</strong></p>
          <p>
            答：在国外留学而回国的人员报考时，选择毕业院校时有留学回国选项，并按系统要求上传毕业证、教育部认可的学历认证书、留学回国人员证明等材料扫描件。
          </p>
          <p><strong>12.报考特岗教师时有哪些优惠政策？</strong></p>
          <p>
            答：生源地考生、参加过“大学生志愿服务西部计划”、“三支一扶”计划且服务期满的志愿者、参加过半年以上实习支教的师范院校毕业生和全日制硕士及以上毕业研究生同等条件下优先招聘。
          </p>
          <p><strong>13.特岗教师的工资待遇如何？</strong></p>
          <p>
            答：特岗教师在聘用期间工资待遇、职称评聘、评优评先、年度考核等方面与当地公办学校教师享受同等待遇。中央财政和省级财政对特岗教师工资性补助标准为年人均3.52万元。
          </p>
          <p><strong>14.特岗教师服务期为多少时间，期满后如何安排？</strong></p>
          <p>
            答：特岗教师服务期为3年。特岗教师服务期满后，经考核合格且愿意留任的特岗教师，在核定的教职工编制总额内办理入编手续，享受当地教师同等待遇；对服务期满后重新择业的特岗教师，设岗县（市）为其重新选择工作岗位提供方便条件和必要的帮助。
          </p>
          <p><strong>15.特岗教师签约后，在服务期内能离职吗？</strong></p>
          <p>
            答：特岗教师因个人原因不能按协议要求履行义务，由本人提出申请，经设岗县（市）同意后，双方可以解除服务协议。特岗教师未能按协议要求履行义务的，设岗县（市）有权主动解除服务协议。
          </p>
          <p><strong>16.特岗教师服务期满后有哪些优惠政策？</strong></p>
          <p>
            答：特岗教师服务期满后，可享受国家引导和鼓励高校毕业生面向基层就业、从事“三支一扶”和扶贫工作的各项优惠政策。
          </p>
          <p><strong>17.特岗教师服务期工龄是怎么计算的？</strong></p>
          <p>
            答：特岗教师服务期满、考核合格且在当地留任后，3年服务期计算为连续工龄。特岗教师服务期满、取得《特岗教师服务证书》后，跨县（区）聘用从教或自行择业的，工龄计算按照有关规定执行。特岗教师未能完成服务期的，从事农村教学工作的服务年限不能计算为工龄。
          </p>
          <p>
            <strong
              >18.特岗教师招聘考试结束以后，特岗报到证，档案怎么弄?</strong
            >
          </p>
          <p>
            答：没签就业协议书的话，带着教体局开的调档函，直接回学校提档案，学校会把报到证一起给你办了。签了就业协议书的话，需要去省就业中心，办理改派手续，办理一个新的报道证，然后再转档案。
          </p>
          <p><strong>19.笔试都考哪些内容，在什么地方进行？</strong></p>
          <p>
            答：特岗教师招聘考试科目按地区有差异，一般指笔试考试科目，分为两种情况：第1种内容为：专业基础知识
            和 教育学、 心理学、 教材教法、 教育法规
            、新课改等相关的教育理论知识。第2种内容为：专业基础知识和公共基础知识。各占150分，总分300分。这个公共基础知识也有不同，有的地区就是指上面的教育理论知识，有的地区是指：文史、法律、数学、政治、时事等综合知识。
          </p>
          <p><strong>20.身份证丢失了怎么办？</strong></p>
          <p>
            答：可以申请办理并使用临时身份证，临时身份证在身份证明上与正式身份证具有同等法律效力。
          </p>
          <p>
            <strong>21.笔试成绩公布后，考生对成绩有异议的该如何处理？</strong>
          </p>
          <p>
            答：请考生查阅各省辖市、省直管县（市）的公告，在规定时间内申请对成绩进行复核。
          </p>
          <p><strong>22.如何确定面试人员？</strong></p>
          <p>
            答：在省定最低分数线以上，按照笔试成绩从高分到低分的顺序，以设岗县（市）分学段、分学科岗位设置数的1:1.2比例依次确定面试人选。根据规定，参加面试的考生笔试成绩不能低于90分（报考体育、音乐、美术岗位笔试成绩不能低于80分）。若符合面试条件的人数达不到面试比例要求，由各省辖市、直管县（市）结合本地实际情况，按照“公开、公平、公正”的原则进行调剂，具体调剂办法由各省辖市、直管县（市）确定。
          </p>
          <p><strong>23.面试资格审核需要携带哪些证件？</strong></p>
          <p>
            答：面试资格审查时需携带面试通知单、身份证、毕业证、教师资格证和照片（一式三份，要求与招聘系统上传照片同一底版），以及面试通知单中要求携带的各项材料。
          </p>
          <p><strong>24.特岗教师招聘采用什么样的分配政策？</strong></p>
          <p>
            答：特岗教师招聘实行“县来县去”、“乡来乡去”的优先录用和岗位分配政策。各地在制定特岗教师招聘的面试考核、调剂递补工作方案及就业岗位分配办法时，要坚持同等条件下生源地考生优先的原则，优先录用本县或周边地区的考生，并充分考虑特岗教师个体家庭因素，优先安排到相对较近的乡镇学校或村小、教学点工作，引导和鼓励特岗教师服务期满后留在当地继续任教。&nbsp;
          </p>
          <p><strong>25.上传证件照、毕业证照片有什么要求？</strong></p>
          <p>
            答：上传证件照要求近期一寸免冠彩色证件照[jpg格式/50KB以内/宽:120px/高:150px/图像模式:RGB色彩模式]；禁止上传生活照、艺术照、集体照、结婚照。毕业证、电子学籍备案表等照片要求可在报名注册页面查询。
          </p>
          <p><strong>26.特岗相比教师招聘有哪些优势?</strong></p>
          <p>
            答：①考试难度比招教考试低②报考条件要求宽松，除体音美外不要求专业对口。③适合继续深造，国家出台文件支持服务期满的特岗教师继续深造，攻读师范类在职硕士研究生免笔试。④作为考进机关事业单位的跳板。服务期满的特岗教师颁发《特岗教师服务证书》，参加市区教师和事业单位招聘有加分政策或定向招聘，每年国家公务员考试和省公务员考试也会有一部分名额用于定向招聘服务期满的特岗教师。
          </p>
          <p><strong>27.服务期满后，特岗的出路在哪里?</strong></p>
          <p>
            答：3年服务期结束后，可继续扎根基层从事农村牧区教育事业；对自愿留任的，经教育行政部门考核(考察)合格，聘用结果报同级人力资源社会保障部门备案，设岗旗县编制部门和教育行政部门分别负责为其落实编制和工作岗位，财政部门负责将其工资发放纳入当地财政统发范围；3年内参加全国硕士研究生招生考试的，初试成绩总分加10分，同等条件下优先录取;专科类毕业生可免试就读区内成人本科；被党政机关或企事业单位正式招聘录用的，不实行见习期，直接转正定级，且“特岗教师”3年服务时间可连续计算为工龄(教龄)和社会保险缴费年限。
          </p>
          <p><strong>28.哪些情况会被解聘？</strong></p>
          <p>
            聘用合同书中规定，如果特岗教师存在下列情形之一，聘用方可以解除合同：伪造学历、健康证明及利用其他不正当手段骗取聘用的；严重违反劳动纪律或任教学校规章制度的；故意不完成工作任务，给教育教学工作造成严重后果的；严重违反教师职业道德，影响恶劣的；严重失职，徇私舞弊，给学校利益造成重大损失的；被依法追究刑事责任的。
          </p>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>
export default {
  name: "commonProblem",
  props: {},
  data() {
    return {
      active: 1,
      titleArr: [
        {
          name: "教师招聘",
          id: 1,
        },
        {
          name: "教师资格证",
          id: 2,
        },
        {
          name: "特岗教师",
          id: 3,
        },
      ],
    };
  },
};
</script>

<style scoped>
.vantabs {
  margin-top: 60px;
}
::v-deep .van-tab {
  font-size: 32px;
}
.content {
  margin-top: 40px;
}
</style>
